import * as React from 'react';
import styledComponents from 'styled-components';
import THEME from '../../../themes';
import treatmentIcons from '../../../components/icons/treatmentIcons';
import GeneralDefinitions from '../../../config/generalDefinitions';

import TreatmentsLayout, { createDiseaseRows } from '../../../components/treatmentsLayout';

const Content = styledComponents.div`
  margin: -40px auto 40px;
  width: 100%;
  max-width: 1200px;
  min-height: 400px;
  
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 0px 80px 0px 0px;
  opacity: 1;

  padding-bottom: 30px;

  @media screen and (max-width: 1290px){
    max-width: 90%;
  }

  @media screen and (max-width: 1024px){
    padding-bottom: 0;
  }

  @media screen and (max-width: 699px){
    max-width: 100%;
    border-radius: 0px 0px 0px 0px;
  }
`;

const OverviewContainer = styledComponents.div`
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  width: 90%;
  margin: 0 auto;

  & > * {
    margin: 20px 30px;
    padding-top: 0;
  }

  @media screen and (max-width: 699px){
    flex-wrap: wrap;
  }
`;

const OverviewText = styledComponents.div`
  font: normal normal normal 22px/32px PT Sans;

  .lead {
    font: normal normal bold 22px/32px PT Sans;
    color: ${THEME.COLORS.PRIMARY};
    text-transform: uppercase;
  }

  @media screen and (max-width: 699px){
    margin: 20px 8px;
  }

`;

const TreatmentIcon = styledComponents.img`
  width: 153px;
  background-color: ${THEME.COLORS.WHITE};
  padding: 0;
  border-radius: 60px;
  margin-top: 50px;
`;

const ClinicalDematology = () => (
  <TreatmentsLayout title='Dermatologia Clínica' pageName='dermClinica'>
    <Content>
      <OverviewContainer>
        <TreatmentIcon src={treatmentIcons.iconClinica} />
        <OverviewText>
          <p className='lead'>
            Dermatologia Clínica
          </p>
          <p>
            Esta área da Dermatologia tem como objetivo <strong>DIAGNOSTICAR, PREVENIR </strong>
            e <strong>TRATAR DOENÇAS DA PELE E ANEXOS</strong> (cabelos, unhas, mucosas),
            além de avaliar minuciosamente o perfil e a história clínica pessoal do paciente,
            sugerindo assim, os melhores e mais adequados tratamentos.
            <br /><br />
            Abaixo, listamos algumas das inúmeras doenças dermatológicas
            as quais auxiliamos no cuidado:
          </p>
        </OverviewText>
      </OverviewContainer>
      {createDiseaseRows(GeneralDefinitions.clinicalDiseases)}
    </Content>
  </TreatmentsLayout>
);

export default ClinicalDematology;
